.main-app {
  width: 100%;
  height: calc(100% - 64px);
}

.options-app {
  height: 64px;
  width: 100%;
  /* background-color: aqua; */
}

.body-app {
  height: calc(100% - 64px);
  width: 100%;
  /* background-color: aquamarine; */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}