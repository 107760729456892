
.csv-container *{
  display: flex;
  justify-content: center;
  align-items: center;
}
.csv-container {
  width: 100%;
}

.csv-container input {
  display: none;
}

.csv-texts{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.csv-area {
  width: 100%;
  background-color: aquamarine;
  height: 150px;
  cursor: pointer;
}

.csv-area-hover {
  width: 100%;
  background-color: aqua;
  height: 150px;
  cursor: pointer;
}

.csv-text {
  font-weight: bold;
}

.csv-result {
  width: 100%;
}
